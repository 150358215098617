import React, { useEffect } from "react";
import logo from "../../Assets/logo/planimg.png";
import rightlogo from "../../Assets/logo/rightlogo.png";
import Aos from "aos";
import "aos/dist/aos.css";
import axios from 'axios'
import { useState } from "react";
const BestPlans = () => {
  useEffect(() => {
    Aos.init({
      duration: 1200,
      offset: 200,
      once: true,
    });
  }, []);


  const [plans,setPlans] = useState([])
  const getALlPlans = ()=>{
      let config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_API_KEY}/plans/getAllPlansByBranchId/674586c19aa6780fb268867d`,
          headers: { }
        };
        
        axios.request(config)
        .then((response) => {
          console.log(response.data);
         
          setPlans(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
  }
  useEffect(()=>{
      getALlPlans();
  },[])

  return (
    <div className="bg-white text-center py-10 mx-6 md:mx-16">
      <div className="text-yellow-500 text-lg">Best Plans</div>
      <h1
        style={{ fontFamily: "Cinzel, serif" }}
        className="text-4xl font-bold mt-2 mb-10"
      >
        Provide Best And Relaxing Service With Best Pricing Plans
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-3 justify-center space-y-6 md:space-y-0 md:space-x-6">
        {plans.map((plan, index) => (
          <div
            key={index}
            className="bg-yellow-50 p-6 rounded-lg shadow-lg"
            data-aos={index === 2 ? "fade-up" : "fade-down"}
          >
            <div className="flex justify-center mb-4">
              <img src={plan.image} alt="Service logo" />
            </div>
            <h2 className="text-2xl font-bold text-brown-700 mb-2">{plan.type}</h2>
            <div className="text-3xl font-bold text-gray-800 mb-2">
              <span>₹</span>{plan.price} <span className="text-lg font-normal">/Per Day</span>
            </div>
            <hr className="border-gray-300 mb-4" />
            <ul className="md:ml-16 space-y-5 mb-6">
              {plan.keyPoints.map((feature, featureIndex) => (
                <li key={featureIndex} className="flex items-center mb-2">
                  <img src={rightlogo} alt="check icon" className="mr-2" />
                  {feature}
                </li>
              ))}
            </ul>
            <button className="mt-4 bg-[#4E2728] text-white font-bold py-2 px-4 rounded shadow hover:bg-yellow-600">
              Book Now
            </button>
          </div>
        ))}
      </div>

    </div>
  );
};

export default BestPlans;

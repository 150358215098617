import React, { useEffect } from 'react';
import OurOffers1 from '../../Assets/about/OurOffersimg1.png';
import OurOffers2 from '../../Assets/about/OurOffersimg2.png';
import OurOffers3 from '../../Assets/about/OurOffersimg3.png';
import OurOffers4 from '../../Assets/about/OurOffersimg4.png';
import Aos from 'aos'
import 'aos/dist/aos.css'
import axios from 'axios'
import { useState } from 'react';
const OurOffers = () => {
    useEffect(() => {
        Aos.init({
          duration: 1200,  
          offset: 200,    
          once: true,
        });
    
      }, [])

      const [offers, setOffers] = useState([]);
      const getAllOffers = () => {
          let config = {
              method: 'get',
              maxBodyLength: Infinity,
              url: `${process.env.REACT_APP_API_KEY}/offer/getAllOffersByBranchId/674586c19aa6780fb268867d`,
              headers: {}
          };
  
          axios.request(config)
              .then((response) => {
                  console.log(response.data);
                  setOffers(response.data)
              })
              .catch((error) => {
                  console.log(error);
              });
  
      }
      useEffect(() => {
          getAllOffers();
      }, [])
    return (
        <div className=" md:mx-16 mx-6 mt-10">
            <h1 className="text-3xl font-bold text-center mb-2">Our Offers</h1>
            <p className="text-center text-gray-600 mb-8">
                We Seek To Build A Lasting Relationship With You Based On Excellence, Integrity And Forward-Thinking. FG
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {offers.map((offer) => (
                    <div 
                        key={offer.id} 
                        className="p-4 rounded-lg shadow-xl "
                        data-aos={offer.animation}
                    >
                        <img
                            src={offer.image}
                            alt={offer.imageAlt}
                            className="w-full rounded-lg mb-4"
                        />
                        <h2 className="text-xl font-bold mb-2">{offer.name}</h2>
                        <p className="text-gray-600">{offer.description}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default OurOffers;

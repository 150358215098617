import React from 'react'
import SimpleBanner from '../../Screens/Common/SimpleBanner'
import AboutUs_banner from '../../Assets/about/about.png'
import YourBeauty from '../../Components/aboutus/YourBeauty'
import BestPlans from '../../Components/aboutus/BestPlans'
import OurServices from '../../Components/home/OurServices'
const About = () => {
  return (
    <div className='overflow-x-hidden'>
       <SimpleBanner
          image={AboutUs_banner}
          name="About Spa Magic"
          path="Home / About us"
        />
        <YourBeauty/>
        <OurServices/>
        <BestPlans/>
    </div>
  )
}

export default About

import React, { useState } from "react";
import img1 from "../../Assets/homeimg/team1.png";
import member1 from "../../Assets/homeimg/member1.jpg";
import member2 from "../../Assets/homeimg/member2.jpg";
import member3 from "../../Assets/homeimg/member3.jpg";
import member4 from "../../Assets/homeimg/member4.jpg";
import axios from "axios";
import { useEffect } from "react";
const OurTeam = () => {
  const teamMembers = [
    { name: " Adya Singh", role: "Massage Specialist", imgSrc: member1 },
    { name: "Aditi Shukla", role: "Facial Specialist", imgSrc: member2 },
    { name: "Puja Chouhan", role: "Scrub Specialist", imgSrc: member3 },
    { name: "Manish Chopra", role: "Haircut Specialist", imgSrc: member4 },
  ];

  const [team, setTeam] = useState([]);

  const showTeam = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/team/getTeamByBranchId/674586c19aa6780fb268867d`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        console.log("Response: ", response.data);
        setTeam(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  console.log("Team: ",team)
  useEffect(() => {
    showTeam();
  }, []);

  return (
    <div className="text-center py-12 relative">
      <h2 className="text-yellow-500 text-lg font-roboto">Our Team</h2>
      <h1
        className="text-4xl font-playfair-display font-bold mt-2 mx-6"
        style={{ fontFamily: "Cinzel, serif" }}
      >
        Relax, You’re In Good Hands
      </h1>
      <p className="text-gray-500 mt-4 mx-6">
        Spas Can Be An Indulgent Luxury Or Part Of A Regular Wellness Routine,
        <br />
        Depending On Personal Preference And Health Goals.
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10 mt-5 mx-6 md:mx-16 ">
        {team.map((member, index) => (
          <div
            key={index}
            className="bg-gradient-to-b from-yellow-500 to-transparent rounded-lg shadow-lg p-4 transition-transform duration-300 transform hover:scale-105"
          >
            <img
              src={member?.profilePic}
              alt={`Portrait of ${member?.name}`}
              className="rounded-lg mb-4 w-full h-[20rem] object-cover"
            />
            <h3 className="text-xl font-playfair-display font-bold">
              {member?.name}
            </h3>
            <p className="text-gray-500">{member?.role}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OurTeam;

import React, { useEffect } from 'react';
import img1 from '../../Assets/homeimg/Swedish.png';
import img2 from '../../Assets/homeimg/HotStone.png';
import img3 from '../../Assets/homeimg/BodyWraps.png';
import img4 from '../../Assets/homeimg/HydrotherapyBaths.png';
import axios from 'axios'
import Aos from 'aos';
import 'aos/dist/aos.css';
import { useState } from 'react';

const OurServices = () => {
    useEffect(() => {
        Aos.init({
          duration: 1200,  
          offset: 200,    
          once: false,
        });
      }, [])
    const [services, setServices] = useState([]);
    const getAllServices = () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_API_KEY}/services//getAllServiceByBranchId/674586c19aa6780fb268867d`,
            headers: {}
        };

        axios.request(config)
            .then((response) => {
                console.log(response.data);
                setServices(response.data)
            })
            .catch((error) => {
                console.log(error);
            });

    }
    useEffect(() => {
        getAllServices();
    }, [])

    return (
        <div className="bg-white text-gray-800  mx-6 md:mx-16">
            <div className="text-center py-12">
                <h2  className="text-yellow-500 text-lg">Our Services</h2>
                <h1  style={{ fontFamily: "Cinzel, serif" }} className="text-4xl font-bold mt-2">Services For You</h1>
                <p className="text-gray-600 mt-4 max-w-2xl mx-auto">
                    Spas Can Be An Indulgent Luxury Or Part Of A Regular Wellness Routine, Depending On Personal Preference And Health Goals.
                </p>
            </div>
            <div>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
                    {services.map((service, index) => (
                        <div
                            key={index}
                            data-aos={service.animation} 
                            className="text-center  transition-transform duration-300 transform hover:scale-105 shadow-xl rounded-md border"
                        >
                            <img src={service.image} alt={service.alt} className="w-full h-48 object-cover rounded-md" />
                            <h3 className="text-xl font-bold my-4 px-8">{service.name}</h3>
                            <p className="text-gray-600 my-4 px-8 ">{service.description}</p>
                            <button className="px-6 py-2 bg-[#4E2728] text-white font-bold rounded my-4">
                                Book Now
                            </button>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default OurServices;

import React from 'react'
import SimpleBanner from '../../Screens/Common/SimpleBanner'
import AboutUs_banner from '../../Assets/about/about.png'
import Map from '../../Components/ContactUs/Map'
import GetTouch from '../../Components/home/GetTouch'
const Contact = () => {
  return (
    <div>
        <SimpleBanner
          image={AboutUs_banner}
          name="Contact"
          path="Home / Contact"
        />
        <GetTouch/>
        <Map/>
    </div>
  )
}

export default Contact

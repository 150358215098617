import React from 'react'
import SimpleBanner from '../Common/SimpleBanner'
import AboutUs_banner from '../../Assets/about/about.png'
import OurServices from '../../Components/home/OurServices'
import GetTouch from '../../Components/home/GetTouch'
const Services = () => {
  return (
    <div className='overflow-x-hidden'>
     <SimpleBanner
          image={AboutUs_banner}
          name="Our Services"
          path="Home / Our Services"
        />
        <OurServices/>
        <GetTouch/>
    </div>
  )
}

export default Services

import React, { useEffect } from 'react'
import SimpleBanner from '../../Screens/Common/SimpleBanner'
import AboutUs_banner from '../../Assets/about/about.png'
import OurOffers from '../../Components/Offers/OurOffers'
import GetTouch from '../../Components/home/GetTouch'
import Aos from 'aos'
import 'aos/dist/aos.css'
const Offers = () => {
    useEffect(() => {
        Aos.init({
          duration: 1200,  
          offset: 200,    
          once: true,
        });
    
      }, [])
    return (
        <div className='overflow-x-hidden'>
            <SimpleBanner
                image={AboutUs_banner}
                name="Offers"
                path="Home / Offers"
            />
            <OurOffers/>
            <GetTouch/>
        </div>
    )
}

export default Offers
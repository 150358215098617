import React from 'react'

import { FaFacebookF, FaInstagram, FaPinterestSquare, FaYoutubeSquare } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
const Footer = () => {
    const navigate = useNavigate();
    return (
        <React.Fragment>
            <footer className="md:py-10 bg-[#4E2728]">
                <div className="px-4 py-10 md:mx-16 mr-28">
                    <div className="grid md:grid-cols-3 grid-cols-1 gap-10 w-[90%] mx-auto">
                        <div className="md:ml-11">
                            <h2 className="text-lg font-bold mb-4 text-[#FFC700]">QUICK LINKS</h2>
                            <ul className='text-white'>
                                <li onClick={() => { navigate('/'); window.scrollTo(600, 0); }} className="mb-2 flex items-center">Home</li>
                                <li onClick={() => { navigate('/about'); window.scrollTo(600, 0); }} className="mb-2 flex items-center">About Us</li>
                                <li onClick={() => { navigate('/services'); window.scrollTo(600, 0); }} className="mb-2 flex items-center">Services</li>
                                <li onClick={() => { navigate('/offers'); window.scrollTo(600, 0); }} className="mb-2 flex items-center">Offers</li>
                                <li onClick={() => { navigate('/contact'); window.scrollTo(600, 0); }} className="mb-2 flex items-center">Contact</li>
                            </ul>
                        </div>
                        <div className="md:text-center md:mr-16 text-white">
                            <h1 className="text-2xl font-bold mb-2 text-[#FFC700] ">KAIRA SPA</h1>
                            <p className="mb-4">Passionate Experts Ready to Serve You: Uniting Skill, Dedication, and Innovation for Your Ultimate Satisfaction.</p>
                            <div className="flex justify-center space-x-4">
                                <div>
                                    <a href='https://www.facebook.com/kairaspabbsr' target="_blank" rel="noopener noreferrer" >
                                        <FaFacebookF /></a>

                                </div>
                                <div>
                                    <a href="https://www.instagram.com/kaira_spa_21" target="_blank" rel="noopener noreferrer">
                                        <FaInstagram />
                                    </a>
                                </div>
                                <FaTwitter />
                                <FaYoutubeSquare />
                                <FaPinterestSquare />
                            </div>
                        </div>
                        <div>
                            <h2 className="text-lg font-bold mb-4 text-[#FFC700]">CONTACT US</h2>
                            <p className="mb-2 text-white"> Hotel Sandy Tower, XIMB Square, Nandankanan Rd, Gajapati Nagar, Bhubaneswar, Odisha 751013</p>
                            <p className="mb-2 text-white"><i className="fas fa-envelope"></i>kairaspa2021@gmail.com</p>
                            <p className="mb-2 text-white"><i className="fas fa-phone"></i> +91 8114882165</p>
                        </div>
                    </div>
                </div>
            </footer>
            <div className="bg-[#FFC700] py-4">
                <div className="container mx-auto px-4 flex flex-wrap justify-between items-center">
                    <p className="w-full sm:w-auto text-center sm:text-left">© 2024 KAIRA SALON  All Rights Reserved.</p>
                    <p className="w-full sm:w-auto text-center sm:text-right">
                        Design & Developed by{' '}
                        <a
                            href="https://www.webbocket.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-500 hover:underline"
                        >
                            WEB_BOCKET
                        </a>
                    </p>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Footer

import React from 'react'

const Map = () => {
    return (
        <React.Fragment>
            <div>
                <div className="w-full h-80 lg:h-[500px] mt-10">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3153.406723501496!2d144.9537354158078!3d-37.816697979751714!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642af0f11fd81%3A0x5045675218ce7e33!2sMelbourne%20VIC%2C%20Australia!5e0!3m2!1sen!2sin!4v1634044575532!5m2!1sen!2sin"
                        width="100%"
                        height="100%"
                        style={{ border: 0 }}
                        allowFullScreen=""
                        loading="lazy"
                    />
                </div>
            </div>
        </React.Fragment>
    )
}

export default Map
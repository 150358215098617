import React, { useRef, useState } from 'react';
import { IoClose } from "react-icons/io5";
import emailjs from 'emailjs-com';
import img from '../../Assets/homeimg/girl-showing.jpg';

const BookModal = ({ onClose }) => {
    const modalRef = useRef();

    const closeModal = (e) => {
        if (modalRef.current === e.target) {
            onClose();
        }
    };

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        emailjs.send('service_xyvcc1e', 'template_voinpgp', formData, 'z7shN8aJoJopttBxt')
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
                onClose(); 
            }, (err) => {
                console.log('FAILED...', err);
            });
    };

    return (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center z-50" ref={modalRef} onClick={closeModal}>
            <div className="bg-white rounded-lg shadow-lg p-6 flex flex-col md:flex-row w-full max-w-4xl">
                {/* Image section, hidden on small screens, visible on md and up */}
              
                {/* Form section */}
                <div className="w-full md:w-1/2 md:pl-6 mt-6 md:mt-0">
                    <div>
                        <IoClose size={23} className="float-right mb-5 cursor-pointer" onClick={onClose} />
                    </div>
                    <form className="space-y-4" onSubmit={handleSubmit}>
                        <input
                            type="text"
                            name="name"
                            placeholder="Name"
                            value={formData.name}
                            onChange={handleChange}
                            className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-300"
                        />
                        <input
                            type="email"
                            name="email"
                            placeholder="Email address"
                            value={formData.email}
                            onChange={handleChange}
                            className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-300"
                        />
                        <input
                            type="tel"
                            name="phone"
                            placeholder="Phone"
                            value={formData.phone}
                            onChange={handleChange}
                            className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-300"
                        />
                        <textarea
                            name="message"
                            placeholder="Message"
                            value={formData.message}
                            onChange={handleChange}
                            className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-300 h-32"
                        ></textarea>
                        <button
                            type="submit"
                            className="w-full bg-[#FFC700] text-white p-3 rounded-lg"
                        >
                            Send Message
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default BookModal;
